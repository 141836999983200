<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Dokter</label>
                    <Dropdown dataKey="id_dokter" v-model="forms.id_dokter" :loading="loadingDropdownDokter"
                        :options="dataDropdownDokter" :class="{ 'p-invalid': errorAdd.id_dokter }"
                        optionLabel="label" optionValue="id_dokter" placeholder="Select Docter"
                        :filter="true" :showClear="true" @filter="searchDropdownDokter($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.id_dokter">{{
                        errorAdd.id_dokter[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="dropdownMedrep">Medrep</label>
                <Listbox ref="dmedrep" v-model="forms.id_mr" :options="dataDropdownMedrep" :class="{ 'p-invalid': errorAdd.id_mr }" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownMedrep($event, 'add')">
                    <template #option="slotProps">
                        <div>{{slotProps.option.label}}</div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.id_mr" >{{ errorAdd.id_mr[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectMedrep(forms.id_mr).length != 0">
                <div class="card" style="height: calc(60vh - 143px)">
                    <DataTable :value="forms.id_mr" :scrollable="true" scrollHeight="flex">
                        <Column field="label" header="Medrep">
                            <template #body="slotProps">
                                {{ slotProps.data.label }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDokter: false,
            loadingDropdownMedrep: false,
            
            // dataDropdown
            dataDropdownDokter: null,
            dataDropdownMedrep: null,

            // addNew
            forms: {
                id_dokter: null,
                id_mr: [],
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownDokter('');
        this.searchDropdownMedrep('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        convertMultiSelectMedrep(data) {
            if(data){
                return data.map((item) => {
                    return item.id_mr;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownDokter(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.ddist.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownDokter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-dokter',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownDokter = res.data.data;
                        this.loadingDropdownDokter = false;
                    }else if(purpose == null){
                        this.dataDropdownDokter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownMedrep(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dmdrp.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownMedrep = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    if(purpose == "add"){
                        this.dataDropdownMedrep = res.data.data;
                        this.loadingDropdownMedrep = false;
                    }else if(purpose == null){
                        this.dataDropdownMedrep = res.data.data;
                    }
                  
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/create-medrep',
                data: {
                    "id_dokter": this.forms.id_dokter,
                    "id_mr": this.convertMultiSelectMedrep(this.forms.id_mr),
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.id_dokter = null;
            this.forms.id_mr = [];
        },
    }
}
</script>