<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <!-- <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message> -->

                <Error :errors="errors"/>

                <Fieldset legend="Import" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid mb-3">       
                        <div class="grid">
                            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                                <h5>Panduan</h5>
                                <ol>
                                    <li>
                                        <p class="line-height-3 m-0">Silahkan download template terlebih dahulu</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan file upload excel atau csv</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan ukuran file tidak lebih dari 2MB</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan kolom terisi dengan benar</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Untuk melihat list KODE CABANG silahkan download dibawah ini</p> 
                                        <Button :loading="loadingdownloadMasterCabang" label="Download Master Cabang" icon="pi pi-download" class="p-button-sm p-button-secondary my-1" @click="downloadMasterCabang('xlsx')" />
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Untuk melihat list KODE MR silahkan download dibawah ini</p> 
                                        <Button :loading="loadingdownloadMasterMedrep" label="Download Master Medrep" icon="pi pi-download" class="p-button-sm p-button-secondary my-1" @click="downloadMasterMedrep('xlsx')" />
                                    </li>
                                </ol>
                                <div class="line-height-3 m-0" style="color:red;">Catatan: ikuti petunjuk langkah demi langkah dengan baik. Sehingga data akan terupload</div>
                                <h5>Download Template</h5>
                                <div class="p-fluid">
                                    <div class="formgrid grid">
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <div class="field mt-2 ml-2">
                                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="lg:col-1">
                                <Divider layout="vertical">
                                    <!-- <b>OR</b> -->
                                </Divider>
                            </div>

                            <div class="sm:col-12 md:col-12 lg:col-6">
                               <BlockUI :blocked="blockPanel">
                                    <form>
                                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Pilih File" uploadLabel="Unggah" cancelLabel="Batal" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                                        <template #empty>
                                            <p>Seret dan taruh file disini untuk mengunggah.</p>
                                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                                        </template>
                                        </FileUpload>
                                    </form>
                                </BlockUI>
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Tambah Baru Dokter" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="name_dokter">Nama Dokter</label>
                                <InputText id="name_dokter" v-model="forms.name_dokter" required="true" autofocus :class="{ 'p-invalid': errorAdd.name_dokter }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.name_dokter" >{{ errorAdd.name_dokter[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownCabang">Kode Cabang</label>
                                <Dropdown v-model="forms.kode_cabang" :loading="loadingDropdownCabangAdd" :options="dataDropdownCabangAdd" optionLabel="kode_cabang" optionValue="kode_cabang" placeholder="Pilih Kode Cabang" :class="{ 'p-invalid': errorAdd.kode_cabang }" :filter="true" :showClear="true" @filter="searchDropdownCabang($event, 'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.kode_cabang" >{{ errorAdd.kode_cabang[0] }}</small>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownMedrep">Medrep</label>
                                <Listbox ref="dmedrep" v-model="forms.id_mr" :options="dataDropdownMedrepAdd" :class="{ 'p-invalid': errorAdd.id_mr }" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownMedrep($event, 'add')">
                                    <template #option="slotProps">
                                        <div>{{slotProps.option.label}}</div>
                                    </template>
                                </Listbox>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.id_mr" >{{ errorAdd.id_mr[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectMedrep(forms.id_mr).length != 0">
                                <div class="card" style="height: calc(60vh - 143px)">
                                    <DataTable :value="forms.id_mr" :scrollable="true" scrollHeight="flex">
                                        <Column field="label" header="Medrep">
                                            <template #body="slotProps">
                                                {{ slotProps.data.label }}
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <Fieldset legend="Tambah Baru Dokter - Medrep" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <CreateDokterMedrep @submit="getDataTable()" ref="create-dokter-medrep"/>
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownCabang">Kode Cabang</label>
                                <Dropdown v-model="filters.kode_cabang" :loading="loadingDropdownCabangFilter" :options="dataDropdownCabangFilter" optionLabel="kode_cabang" optionValue="kode_cabang" placeholder="Pilih Kode Cabang" :filter="true" :showClear="true" @filter="searchDropdownCabang($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="id_dokter" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="name_dokter" header="Nama Dokter">
                        <template #body="slotProps">
                            {{slotProps.data.name_dokter}}
                        </template>
                    </Column>
                    <Column field="kode_dokter" header="Kode Dokter">
                        <template #body="slotProps">
                            {{slotProps.data.kode_dokter}} 
                        </template>
                    </Column>
                    <Column field="kode_cabang" header="Kode Cabang">
                        <template #body="slotProps">
                            {{slotProps.data.kode_cabang}} 
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Kode MR">
                        <template #body="slotProps">
                            {{slotProps.data.kode_mr}}
                        </template>
                    </Column>
                    <Column field="name_mr" header="Nama MR">
                        <template #body="slotProps">
                            {{slotProps.data.name_mr}}
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)"  v-if="permission.includes('update')"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteItem(slotProps.data)" v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name_dokter">Nama Dokter</label>
                        <InputText id="name_dokter" v-model="item.name_dokter" required="true" autofocus :class="{ 'p-invalid': errorEdit.name_dokter }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.name_dokter" >{{ errorEdit.name_dokter[0] }}</small >
					</div>
                    <div class="field">
                        <label for="dropdownEditCabang">Kode Cabang</label>
                        <Dropdown ref="dcbng" v-model="item.kode_cabang" :loading="loadingDropdownCabangEdit" :options="dataDropdownCabangEdit" optionLabel="kode_cabang" optionValue="kode_cabang" placeholder="Pilih Kode Cabang" :filter="true" :showClear="true" @filter="searchDropdownCabang($event, 'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.kode_cabang" >{{ errorEdit.kode_cabang[0] }}</small>
                    </div>
                    <div class="field">
                        <label for="dropdownEditMedrep">Medrep</label>
                        <Dropdown ref="dmdrp" v-model="item.id_mr" :loading="loadingDropdownMedrepEdit" :options="dataDropdownMedrepEdit" optionLabel="label" optionValue="id_mr" placeholder="Pilih Medrep" :filter="true" :showClear="true" @filter="searchDropdownMedrep($event, 'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.id_mr" >{{ errorEdit.id_mr[0] }}</small>
                    </div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
                
                <Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Konfirmasi" :modal="true" class="p-fluid">
                    <div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="item">Apakah anda yakin menghapus dokter <b>{{item.name_dokter}}</b> dan medrep <b>{{item.master_medrep_x_dokter?.medrep?.name_mr}}</b> ini ?</span>
					</div>
					<template #footer>
						<Button label="Tidak" icon="pi pi-times" class="p-button-text" @click="hideDeleteDialog"/>
						<Button label="Ya" icon="pi pi-check" class="p-button-text" @click="deletingItem" :loading="loadingDelete"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import CreateDokterMedrep from '../../components/master/dokter/CreateDokterMedrep.vue';
import Error from '../../components/Error.vue';

export default {
	components: {
		'Error': Error,
		'CreateDokterMedrep': CreateDokterMedrep,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDelete: false,
            loadingDropdownCabangFilter: false,
            loadingDropdownCabangAdd: false,
            loadingDropdownCabangEdit: false,
            loadingDropdownMedrepAdd: false,
            loadingDropdownMedrepEdit: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDownloadTemplate: false,
            loadingdownloadMasterCabang: false,
            loadingdownloadMasterMedrep: false,

            // upload
            blockPanel: false,
            file: null,

            // dataDropdown
            dataDropdownCabangFilter: null,
            dataDropdownCabangAdd: null,
            dataDropdownCabangEdit: null,
            dataDropdownMedrepAdd: null,
            dataDropdownMedrepEdit: null,

            // addNew
            forms: {
                name_dokter: null,
                kode_cabang: null,
                id_mr: [],
            },

            // edit
            item: {},
            editItemDialog: false,
            deleteItemDialog: false,
            
            // filter
            filters: {
                kode_cabang: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownCabang('');
        this.searchDropdownMedrep('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        convertMultiSelectMedrep(data) {
            if(data){
                return data.map((item) => {
                    return item.id_mr;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownCabang(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dcbng.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownCabangAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownCabangEdit = true;
                }else if(purpose == "filter"){
                    this.loadingDropdownCabangFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-cabang',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    if(purpose == "add"){
                        this.dataDropdownCabangAdd = res.data.data;
                        this.loadingDropdownCabangAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownCabangEdit = res.data.data;
                        this.loadingDropdownCabangEdit = false;
                    }else if(purpose == "filter"){
                        this.dataDropdownCabangFilter = res.data.data;
                        this.loadingDropdownCabangFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownCabangAdd = res.data.data;
                        this.dataDropdownCabangEdit = res.data.data;
                        this.dataDropdownCabangFilter = res.data.data;
                    }
                  
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownMedrep(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dmdrp.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownMedrepAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownMedrepEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    if(purpose == "add"){
                        this.dataDropdownMedrepAdd = res.data.data;
                        this.loadingDropdownMedrepAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownMedrepEdit = res.data.data;
                        this.loadingDropdownMedrepEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownMedrepAdd = res.data.data;
                        this.dataDropdownMedrepEdit = res.data.data;
                    }
                  
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // DOWNLOADTEMPLATE
        downloadTemplate(ext){
            this.loadingDownloadTemplate= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/generate_template',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Template Master Dokter.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingDownloadTemplate= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingDownloadTemplate= false;
            });
        },
        downloadMasterCabang(ext){
            this.loadingdownloadMasterCabang= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/list/master-cabang',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'List Master Cabang.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingdownloadMasterCabang= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingdownloadMasterCabang= false;
            });
        },
        downloadMasterMedrep(ext){
            this.loadingdownloadMasterMedrep= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/list/master-medrep',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'List Master Medrep.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingdownloadMasterMedrep= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingdownloadMasterMedrep= false;
            });
        },
        //UPLOAD
        onUpload(event) {
            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/import',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;
                this.getDataTable();
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/create',
                data: {
                    "name_dokter": this.forms.name_dokter,
                    "kode_cabang": this.forms.kode_cabang,
                    "id_mr": this.convertMultiSelectMedrep(this.forms.id_mr),
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.name_dokter = null;
            this.forms.kode_cabang = null;
            this.forms.id_mr = [];
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            // dropdown 
            this.searchDropdownCabang('', 'edit', item.kode_cabang);
            this.searchDropdownMedrep('', 'edit', item.id_mr != null ? item.kode_mr +"-"+ item.name_mr : null );

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/update',
                data: {
                    "id_mrdokter" : this.item.id_mrdokter,
                    "name_dokter" : this.item.name_dokter,
                    "kode_cabang" : this.item.kode_cabang,
                    "id_mr" : this.item.id_mr,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DELETE
		deleteItem(item) {
			this.item = item;
			this.deleteItemDialog = true;
		},
		hideDeleteDialog() {
			this.deleteItemDialog = false;
			this.submitted = false;
		},
		deletingItem() {
            this.loadingDelete = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/delete',
                data: {
                    "id_mrdokter" : this.item.id_mrdokter,
                },
            })
            .then((res) => {
                this.loadingDelete = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Dihapus', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.deleteItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingDelete = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Dihapus', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-dokter',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "kode_cabang" : this.filters.kode_cabang,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-dokter/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "kode_cabang" : this.filters.kode_cabang,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Dokter Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>